import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Block() {
  let { num } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.post(`/block/${num}`).then((res) => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {!loading ? (
        <div class="page p-2  px-5 bg-white rounded mt-5 ">
          <div class="font-medium text-center text-red-500">
            SMS İptali Başarılı{" "}
          </div>
          <div class="text-slate-400 font-medium text-center">
            Bir daha size sms göndermeyeceğiz.
          </div>
        </div>
      ) : (
        <div className="w-[100px] mx-auto bg-transparent flex items-center justify-center h-36">
          <i className="fa-solid fa-spin fa-spinner text-3xl"></i>
        </div>
      )}
    </div>
  );
}

export default Block;
