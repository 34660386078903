import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

export default function SMSGonder({ refreshAmount }) {
  const [dropdown, setDropdown] = useState(false);
  const [ara, setAra] = useState("");
  const [tab, setTab] = useState("kisi");
  const [type, setType] = useState("tek");
  const [selectedAy, setSelectedAy] = useState([]);
  const [icerik, setIcerik] = useState("");
  const [baslik, setBaslik] = useState("");
  const [ok, setOk] = useState(null);
  const [gruplar, setGruplar] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [cik, setCik] = useState(true);
  const [kisayollar, setKisayollar] = useState(null);
  const [selectedGrup, setSelectedGrup] = useState("");
  const [baslikList, setBaslikList] = useState(null);
  useEffect(() => {
    if (tab == "grup") {
      axios.get("/getGrup").then((res) => {
        setGruplar(res.data);
      });
    } else {
      setGruplar(null);
    }

    setSelectedGrup("");
  }, [tab]);

  useEffect(() => {
    if (selectedGrup == "") {
      axios.get("/getKisayollar").then((res) => {
        setKisayollar(res.data);
      });
    } else {
      setKisayollar(null);
      axios.get(`/getKisayollar?grup=${selectedGrup}`).then((res) => {
        setKisayollar(res.data);
      });
    }
  }, [selectedGrup]);

  useEffect(() => {
    axios.get("/basliklar").then((res) => {
      setBaslikList(res.data.response.senders.sender);
    });

    axios.get("/getKisayollar").then((res) => {
      setKisayollar(res.data);
    });
  }, []);

  const [numaralar, setNumaralar] = useState([
    {
      numara: "",
    },
  ]);

  function sms(e) {
    if (
      numaralar.filter(
        (f) => !isNaN(f.numara.split(" ").join("")) && f.numara != ""
      ).length == 0 &&
      tab != "grup"
    ) {
      alert("Numara girişi yapınız");
      return;
    }

    if (baslik == "") {
      alert("Başlık Seçiniz");
      return;
    }

    if (icerik == "") {
      alert("Mesaj İçeriği boş olamaz ");
      return;
    }

    if (!window.confirm("Emin misiniz")) {
      return;
    }

    e.currentTarget.innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>";

    if (type == "aylik") {
      const data = [];

      selectedAy.map((val) => {
        data.push({
          gun: val + 1,
          icerik: icerik,
          baslik: baslik,
          cik: cik,
          selectedGrup,
          numaralar: numaralar
            .filter(
              (f) => !isNaN(f.numara.split(" ").join("")) && f.numara != ""
            )
            .map((val) => val.numara),
        });
      });

      axios.post("/save_sms/", data).then((res) => {
        setOk({
          message: "Girilen numaralara otomatik sms kaydı yapılmıştır.",
        });

        refreshAmount();
      });
    } else if (type == "tek") {
      axios
        .post("/sms_gonder", {
          icerik: icerik,
          baslik: baslik,
          selectedGrup,
          cik: cik,
          who: localStorage.sms_token,
          numaralar: numaralar
            .filter(
              (f) => !isNaN(f.numara.split(" ").join("")) && f.numara != ""
            )
            .map((val) => val.numara),
        })
        .then((res) => {
          setOk({
            message: "Girilen numaralara sms gönderimi yapılmıştır.",
          });

          refreshAmount();
        });
    }
  }

  function search(e) {
    setAra(e.target.value);
    if (e.target.value == "") {
      setSearchResult(null);
      return;
    }
    axios.get(`/musteriSearch?search=${e.target.value}`).then((res) => {
      setSearchResult(res.data);
    });
  }

  return (
    <>
      <div className="page p-2 rounded-lg shadow-sm">
        {ok ? (
          <div className="flex items-center py-4 in justify-center flex-column">
            <i className="text-green-400 text-[6rem]  fa-solid fa-circle-check"></i>
            <div className="font-medium text-green-400 text-2xl mt-2">
              Başarılı
            </div>
            <div className="text-slate-400 font-medium">{ok.message}</div>
            <div className="flex mt-2">
              <button
                onClick={(e) => {
                  setSelectedAy([]);
                  setIcerik("");
                  setOk(null);
                  setBaslik("");
                  setNumaralar([
                    {
                      numara: "",
                    },
                  ]);
                }}
                className="px-3 py-1 rounded bg-green-400 hover:bg-green-500 font-medium text-sm"
              >
                Tekrar Gönder
              </button>
              <Link
                to={"/"}
                className="px-3 py-1 block rounded bg-slate-200 hover:bg-slate-300 ms-2 font-medium text-sm"
              >
                Ana Sayfa
              </Link>
            </div>
          </div>
        ) : (
          <div className="row in gx-4">
            <div className="col-lg-6 col-12">
              <div>
                <div className="row g-0 mb-2">
                  <div className="col-6">
                    <div
                      onClick={(e) => setTab("kisi")}
                      className={`text-center py-2 ${
                        tab == "kisi" ? "tab-active" : "tab-item"
                      }`}
                    >
                      <i className="fa-solid fa-user "></i> Kişilere SMS
                    </div>
                  </div>

                  <div className="col-6">
                    <div
                      onClick={(e) => setTab("grup")}
                      className={`text-center py-2 ${
                        tab == "grup" ? "tab-active" : "tab-item"
                      }`}
                    >
                      <i className="fa-solid fa-user-group "></i> Grup SMS
                    </div>
                  </div>
                </div>

                {tab == "kisi" ? (
                  <div className="in">
                    <div className="in relative">
                      <div className="musteri-search bg-slate-50 rounded flex px-2 py-1 items-center">
                        <i className="fa-solid text-slate-400 fa-magnifying-glass"></i>
                        <input
                          onClick={() => setDropdown(!dropdown)}
                          type="text"
                          onPaste={(e) => {
                            const data = e.clipboardData.getData("text");
                            const a = [...numaralar];

                            data.split("\n").forEach((val, ind) => {
                              if (val.split("\r").join("") != "") {
                                var num = val;

                                if (num.charAt(0) != "5") {
                                  if (num.charAt(0) == "0") {
                                    num = num.substring(1);
                                  } else if (
                                    num.charAt(0) == "9" &&
                                    num.charAt(1) == "0"
                                  ) {
                                    num = num.substring(1);
                                    num = num.substring(1);
                                  } else {
                                    alert("Desteklenmeyen numara: " + val);
                                  }
                                }

                                a.push({ numara: num });
                              }
                            });

                            setDropdown(false);
                            setNumaralar([
                              ...a.filter((n) => n.numara != ""),
                              { numara: "" },
                            ]);

                            setTimeout(() => {
                              setAra("");
                              setSearchResult(null);
                            }, 300);
                          }}
                          value={ara}
                          onChange={(e) => {
                            search(e);
                          }}
                          placeholder="Müşteri ara veya numara yapıştır"
                          className="flex-fill text-sm"
                        />

                        {ara != "" ? (
                          <i
                            className="fa-sharp cursor-pointer fa-solid fa-xmark in text-lg"
                            onClick={() => {
                              setAra("");
                              setSearchResult(null);
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>

                      {searchResult ? (
                        <div className=" musteri-dropdown in shadow-lg absolute ">
                          <div className="mt-2 flex flex-wrap ">
                            {searchResult && searchResult.length == 0 ? (
                              <div className="flex-fill flex flex-column items-center justify-between py-2">
                                <i className="fa-solid fa-cancel text-slate-400"></i>
                                <div className="font-medium text-slate-400">
                                  Veri bulunamadı
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {searchResult ? (
                              searchResult.map((val) => {
                                return (
                                  <div
                                    key={val.musteri_id}
                                    className="mb-1 in  me-1 bg-white hover:bg-gray-100 cursor-pointer px-2 py-1 shadow-sm rounded"
                                    onClick={(e) => {
                                      setSearchResult(null);
                                      setAra("");
                                      setNumaralar([
                                        ...numaralar,
                                        { numara: val.telefon },
                                      ]);
                                    }}
                                  >
                                    <div className="font-medium text-center text-sm leading-3">
                                      {val.ad_soyad}
                                    </div>
                                    <div className="font-medium text-center mt-1 text-xs text-pri leading-3">
                                      {val.telefon}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div>loading</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mt-2 in">
                      <div className="row g-2">
                        <div className="col-12">
                          {numaralar.length != 1 ? (
                            <div
                              onClick={(e) => {
                                if (window.confirm("Emin misiniz?")) {
                                  setNumaralar([
                                    {
                                      numara: "",
                                    },
                                  ]);
                                }
                              }}
                              className="leading-3 text-sm underline text-pri text-end cursor-pointer hover:text-red-600"
                            >
                              Tümünü sil
                            </div>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        {numaralar.map((val, i) => {
                          return (
                            <div className="col-6" key={i}>
                              <div
                                className={`
                            input-dogrula
                            ${
                              val.numara.split(" ").join("").length >= 10 &&
                              !isNaN(val.numara.split(" ").join(""))
                                ? "input-dogrula-ok"
                                : "input-dogrula-hata"
                            }
                            numara-select  in relative`}
                              >
                                <InputMask
                                  onContextMenu={(e) => {
                                    e.preventDefault();
                                    const a = [...numaralar];

                                    a[i] = { numara: "" };
                                    setNumaralar([...a]);
                                  }}
                                  value={val.numara}
                                  onChange={(e) => {
                                    const a = [...numaralar];
                                    a[i] = { numara: e.target.value };

                                    if (
                                      a.filter((n) => n.numara == "").length ==
                                      0
                                    ) {
                                      setNumaralar([...a, { numara: "" }]);
                                    } else {
                                      setNumaralar([...a]);
                                    }
                                  }}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    alert(
                                      "Numara yapıştırma işlemini buraya yapmayınız"
                                    );
                                  }}
                                  placeholder="5xx xxx xxxx"
                                  className="e-i"
                                  mask="599 999 9999"
                                  maskChar=""
                                />
                                {!isNaN(val.numara.split(" ").join(""))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="in">
                    <div className="flex flex-wrap">
                      {gruplar ? (
                        gruplar.map((val, i) => {
                          return (
                            <div
                              key={i}
                              onClick={(e) => setSelectedGrup(val.grup_ismi)}
                              className={`${
                                selectedGrup == val.grup_ismi
                                  ? "grup-active"
                                  : ""
                              } in cursor-pointer hover:bg-slate-200 text-sm bg-slate-100 rounded-full px-3 py-1 font-medium me-2 mb-2`}
                            >
                              {val.grup_ismi} ({val.kisi})
                            </div>
                          );
                        })
                      ) : (
                        <div className="flex flex-fill py-3 items-center justify-center">
                          {" "}
                          <i className="fa-solid fa-spinner fa-spin"></i>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div>
                <div className="mb-2 flex items-center justify-between">
                  <div className="font-medium">
                    <i className="fa-solid fa-comment-sms"></i> SMS Başlığı
                  </div>
                  <select
                    value={baslik}
                    onChange={(e) => setBaslik(e.target.value)}
                    className="rounded text-sm  bg-slate-100 p-2"
                  >
                    <option value="" disabled required>
                      Seçiniz
                    </option>
                    {baslikList
                      ? baslikList.map((val, i) => {
                          return (
                            <option key={i} value={val}>
                              {val}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
                <div className="font-medium text-sm">
                  <i className="fa-regular fa-comment"></i> Mesaj İçeriği{" "}
                  {Number(icerik.length) != 0
                    ? `(${Number(icerik.length)})`
                    : ""}
                </div>

                {Math.floor(Number(icerik.length) / 150) + 1 != 1 ? (
                  <div className="in leading-4 font-medium leading-4 text-slate-400 text-sm ">
                    Harcanacak Kredi :{" "}
                    {Math.floor(Number(icerik.length) / 150) + 1}
                  </div>
                ) : (
                  ""
                )}
                <textarea
                  onChange={(e) => setIcerik(e.target.value)}
                  className="e-i mt-2"
                  placeholder="Mesaj İçeriği"
                  value={icerik}
                ></textarea>

                <div className="flex items-center  mb-2">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value="cik"
                    checked={cik}
                    onChange={(e) => {
                      setCik(e.target.checked);
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer rounded focus:ring-blue-500  focus:ring-2 "
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium cursor-pointer text-gray-900"
                  >
                    Abonelikten çık mesajı dahil edilsin mi?
                  </label>
                </div>

                {type == "aylik" ? (
                  <>
                    <div className="font-medium text-slate-600 text-sm text-center">
                      Günleri Seçiniz
                    </div>
                    <div className="in ay-select mb-2">
                      {[...Array(30).keys()].map((val, i) => {
                        return (
                          <div
                            onClick={(e) => {
                              if (selectedAy.includes(val)) {
                                const a = [...selectedAy];

                                setSelectedAy(a.filter((n) => n != val));
                              } else {
                                setSelectedAy([...selectedAy, val]);
                              }
                            }}
                            className={`ay ${
                              selectedAy.includes(val) ? "ay-active" : ""
                            }`}
                            key={i}
                          >
                            {val + 1}
                          </div>
                        );
                      })}
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                <div className="row g-2">
                  <div className="col-6">
                    <div
                      onClick={(e) =>
                        setType(type == "aylik" ? "tek" : "aylik")
                      }
                      className="e-r bg-slate-200 text-slate-600 hover:bg-slate-300"
                    >
                      {type == "aylik" ? (
                        <span className="in">
                          {" "}
                          <i className="fa-solid fa-paper-plane"></i> Anlık
                        </span>
                      ) : (
                        <span className="in">
                          {" "}
                          <i className="fa-solid fa-clock-rotate-left"></i>{" "}
                          Zamanla
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-6">
                    <div
                      onClick={(e) => sms(e)}
                      className="e-r bg-pri hover:bg-red-600 text-white"
                    >
                      {type == "aylik" ? (
                        <span className="in">
                          {" "}
                          <i className="fa-regular fa-save"></i> Kaydet
                        </span>
                      ) : (
                        <span className="in">
                          <i className="fa-regular fa-paper-plane"></i> Gönder
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {kisayollar && kisayollar.length != 0 ? (
        <div className="page p-2 rounded-lg shadow-sm">
          <div className="row g-2">
            <div className="col-12">
              <div className="font-medium text-slate-600">
                {selectedGrup != ""
                  ? selectedGrup + " müşterileri için kısayol sms metinleri"
                  : "Kısayol sms metinleri"}
              </div>
            </div>
            {kisayollar &&
              kisayollar.map((val) => {
                return (
                  <div className="col-lg-4 col-6" key={val.ks_id}>
                    <div
                      onClick={() => {
                        setIcerik(val.icerik);
                      }}
                      className="p-2 h-full flex items-center justify-center text-center text-sm font-medium active:scale-90 transition-all shadow hover:bg-slate-200 cursor-pointer rounded-lg bg-slate-100"
                    >
                      {val.icerik}
                      {selectedGrup == "" ? (
                        <span className="text-red-500 ms-1">
                          {val.to_grup ? `(${val.to_grup})` : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
