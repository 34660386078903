import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Header from "./comp/Header";
import Index from "./pages/Index";
import "./assets/css/bootstrap-grid.min.css"
import "./assets/css/style.css"
import SMSGonder from "./pages/SMSGonder";
import Musteriler from "./pages/Musteriler";
import { useEffect, useState } from "react";
import axios from "axios";
import Login from "./pages/Login";
import Block from "./pages/Block";
import Ayarlar from "./pages/Ayarlar";
import Onay from "./pages/Onay";

function App() {
  const [sms, setSms] = useState(null);

  useEffect(()=>{
    refreshAmount()
  },[])
  function refreshAmount(){
    setSms(null);
    axios.get(`/bakiye`).then(res=>{
      
      setSms(res.data.response.balance.sms)
  })
  }
  return (
    <Router>
    <div className="px-2 md:p-0  App bg-gray-100">
    


      {localStorage.sms_token ? <>
        <Header amount={sms}/>
        <Routes>
            <Route path="/" element={<Index/>} />
            <Route path="/sms-gonder" element={<SMSGonder refreshAmount={()=>refreshAmount()}/>} />
            <Route path="/musteriler" element={<Musteriler/>} />
            <Route path="/ayarlar" element={<Ayarlar/>} />
            <Route path="/u/:num" element={<Block/>} />
        <Route path="/onay" element={<Onay/>} />
            
        </Routes>
      <div className="footer mt-2">
        <div className="text-slate-600 font-medium text-end text-sm">{new Date().getFullYear()} © MHD HOLDING </div>
      </div>
      </>
      :  
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/u/:num" element={<Block/>} />
        <Route path="/onay" element={<Onay/>} />
      </Routes>
       }

      
     
    </div>
    </Router>
  );
}

export default App;
