import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";

function Musteriler() {
  const [tab, setTab] = useState("");
  const [ok, setOk] = useState(false);
  const [musteriler, setMusteriler] = useState(null);
  const [grupList, setGrupList] = useState(null);
  const [selectedGruplar, setSelectedGruplar] = useState([]);
  const [grupSec, setgrupSec] = useState("");
  const [orj, setOrj] = useState(null);
  const [secili, setSecili] = useState([]);
  const [blocked, setBlocked] = useState([]);
  const [popup, setPopup] = useState(false);
  const [table, setTable] = useState("musteri");
  const [kisayollar, setKisayollar] = useState(null);
  const [pasted,setPasted] = useState([]);
  const [pasteString,setPasteString] = useState("")

  useEffect(() => {
    setTable("musteri");
  }, [tab]);
  useEffect(() => {
    setMusteriler(null);
    if (grupSec == "") {
      axios.get(`/getMusteri`).then((res) => {
        setMusteriler(res.data);
      });
    } else {
      axios.get(`/getMusteri?grup=${grupSec}`).then((res) => {
        setMusteriler(res.data);
      });
    }
  }, [grupSec]);

  useEffect(() => {
    axios.get(`/getMusteri?`).then((res) => {
      setMusteriler(res.data);
      setOrj(res.data.length);
    });

    axios.get(`/getGrup`).then((res) => {
      const a = [];
      res.data.forEach((val) => {
        a.push({
          value: val.grup_ismi,
          label: val.grup_ismi,
          count: val.kisi,
        });
      });

      setGrupList(a);
    });

    axios.get("/blockedNumbers").then((res) => {
      setBlocked(res.data);
    });

    axios.get("/getKisayollar").then((res) => {
      setKisayollar(res.data);
    });
  }, []);

  function addMusteri(e) {
    e.preventDefault();
    e.target[3].innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>";
    axios
      .post("/addMusteri", {
        ad_soyad: e.target[0].value,
        telefon: e.target[1].value,
        gruplar: selectedGruplar,
      })
      .then((res) => {
        axios.get(`/getMusteri`).then((res) => {
          setMusteriler(res.data);
          setOrj(res.data.length);
        });

        setSelectedGruplar([]);
        e.target[3].innerHTML = "Kaydet";
        setOk(true);
        setgrupSec("");
        e.target.reset();
      });
  }

  function addGrup(e) {
    e.preventDefault();
    e.target[1].innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>";
    axios
      .post("/addGrup", {
        isim: e.target[0].value,
      })
      .then((res) => {
        setSelectedGruplar([]);
        e.target[1].innerHTML = "Kaydet";
        setOk(true);
        setgrupSec("");
        e.target.reset();

        axios.get(`/getGrup`).then((res) => {
          const a = [];
          res.data.forEach((val) => {
            a.push({
              value: val.grup_ismi,
              label: val.grup_ismi,
              count: val.kisi,
            });
          });

          setGrupList(a);
        });
      });
  }
  return (
    <div className="page p-2 in rounded-lg shadow-sm">
      {popup ? (
        <div className="fixed top-0 left-0 bg-zinc-900/70 w-full h-full flex justify-center z-[100000000000] ">
          <div className="bg-white  in  rounded self-start mt-5 w-[450px] ">
            <div className="bg-red-200 text-red-600 flex items-center justify-between rounded-b-none p-2 rounded font-medium w-full">
              <div>
                {" "}
                <i className="fa-solid fa-cancel"></i> Engellenmiş Numaralar (
                {blocked ? blocked.length : ""})
              </div>

              <div onClick={(e) => setPopup(false)}>
                <i className="fa-solid fa-xmark text-slate-600 hover:text-slate-800 cursor-pointer text-lg"></i>
              </div>
            </div>

            {blocked && blocked.length == 0 ? (
              <div className="bg-amber-200 m-2 text-amber-600 border-amber-600 border-2 p-2 rounded font-medium">
                Engellenmiş numara bulunmamaktadır.
              </div>
            ) : (
              ""
            )}

            <div className="p-2 flex flex-wrap gap-2">
              {blocked &&
                blocked.map((val, i) => {
                  return (
                    <div
                      key={val.id}
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "Bu numaranın engelini kaldırmak  istediğinize emin misiniz"
                          )
                        ) {
                          e.target.style.opacity = "0.5";
                          axios
                            .post(`/delete/blocked_numbers/id/${val.id}`)
                            .then((res) => {
                              setBlocked(res.data);
                            });
                        }
                      }}
                      className="cursor-pointer hover:bg-slate-200 font-medium text-slate-600 p-2 rounded bg-slate-100"
                    >
                      {val.number}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row g-3">
        <div className="col-lg-4 col-12 ">
          <div>
            {tab == "" ? (
              <div className="flex flex-column items-center py-3 in">
                <i className="fa-solid fa-address-book text-xl text-pri"></i>
                <div className="font-medium text-pri text-xl">Rehber</div>
                <div className="text-slate-400 leading-4 text-center px-3 text-sm">
                  Müşterilerinizi ve gruplarınızı yönetebilir ve
                  görüntüleyebilirsiniz
                </div>
                <div className="row g-2 w-full mt-3">
                  <div className="col-6">
                    <div className="flex flex-column items-center justify-center">
                      <div className="font-medium text-pri font-medium text-xl text-center">
                        {orj ? orj : "-"}
                      </div>
                      <div className="font-medium text-center text-slate-600  leading-3 text-sm">
                        <i className="fa-solid fa-user"></i> Müşteri
                      </div>
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => setTab("musteri-ekle")}
                          className="text-slate-600 w-6 h-6 text-sm me-1 cursor-pointer hover:bg-slate-200 bg-slate-100 rounded"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="flex flex-column items-center justify-center">
                      <div className="font-medium text-pri font-medium text-xl text-center">
                        {grupList ? grupList.length : "-"}
                      </div>
                      <div className="text-slate-600 font-medium text-center leading-3 text-sm">
                        <i className="fa-solid fa-user-group"></i> Grup
                      </div>
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => setTab("grup-ekle")}
                          className="text-slate-600 w-6 h-6 text-sm me-1 cursor-pointer hover:bg-slate-200 bg-slate-100 rounded"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                        <button
                          onClick={() => setTab("grup-edit")}
                          className="text-slate-600 w-6 h-6 text-sm cursor-pointer hover:bg-slate-200 bg-slate-100 rounded"
                        >
                          <i className="fa-solid fa-edit"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="flex flex-column items-center justify-center">
                      <div className="font-medium text-pri font-medium text-xl text-center">
                        {kisayollar ? kisayollar.length : "-"}
                      </div>
                      <div className="text-slate-600 font-medium text-center leading-3 text-sm">
                        <i className="fa-solid fa-message"></i> Kısayol
                      </div>
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => setTable("kisayol")}
                          className="text-slate-600 w-6 h-6 text-sm me-1 cursor-pointer hover:bg-slate-200 bg-slate-100 rounded"
                        >
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="flex flex-column items-center justify-center">
                      <div className="font-medium text-pri font-medium text-xl text-center">
                        {blocked ? blocked.length : "-"}
                      </div>
                      <div className="text-slate-600 font-medium text-center leading-3 text-sm">
                        <i className="fa-solid fa-cancel"></i> Engellenmiş
                      </div>
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => setPopup(true)}
                          className="text-slate-600 w-6 h-6 text-sm me-1 cursor-pointer hover:bg-slate-200 bg-slate-100 rounded"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {tab == "musteri-ekle" ? (
              <div className="flex flex-column items-center in">
                <div className="flex items-center w-full">
                  <button
                    onClick={() => setTab("")}
                    className="rounded  text-zinc-600 hover:bg-slate-200  pe-2 text-xs"
                  >
                    <i className="fa-solid fa-chevron-left "></i>
                    Geri
                  </button>
                  <div className="font-medium mx-auto text-pri">
                    Müşteri Ekle
                  </div>
                </div>

                <form
                  id="musteriForm"
                  className="w-full mt-2"
                  onSubmit={(e) => addMusteri(e)}
                >
                  <div className="mb-2">
                    <div className="font-medium text-sm">Ad Soyad </div>
                    <input
                      type="text"
                      onChange={() => setOk(false)}
                      placeholder="Ad Soyad"
                      required
                      className="e-i p-1 text-sm"
                    />
                  </div>

                  <div className="mb-2">
                    <div className="font-medium text-sm">Telefon </div>
                    <input
                      type="text"
                      onChange={() => setOk(false)}
                      placeholder="Telefon (5xx)"
                      required
                      className="e-i p-1 text-sm"
                    />
                  </div>

                  {grupList ? (
                    <div className="mb-2">
                      <div className="font-medium text-sm">Gruplar </div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#f1f5f9",
                            border: "none",
                          }),
                          multiValueLabel: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "white",
                          }),

                          multiValueRemove: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#fca5a5",
                          }),
                        }}
                        //  value={seciliGruplar}
                        value={selectedGruplar}
                        onChange={(e) => {
                          setSelectedGruplar(e);
                        }}
                        form="musteriForm"
                        placeholder="Seç"
                        options={grupList}
                        isMulti={true}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex">
                    {ok ? (
                      <button className="text-xs in text-green-500 font-medium">
                        <i className="fa-solid fa-check"></i> Kaydedildi
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      type="submit"
                      className="e-btn bg-red-500 px-5 ms-auto d-block"
                    >
                      Kaydet
                    </button>
                  </div>
                </form>
              

                <div className="mt-4 font-medium mx-auto text-pri">
                    Çoklu Müşteri Ekle (Yapıştır)
                  </div>
              

                  <form
                  className="w-full mt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if(pasted.length == 0){
                      alert("En az 1 numara ekleyiniz.")
                      return
                    }


                    axios.post("/addMultiMusteri",{
                      grup: selectedGruplar,
                      numaralar: pasted
                    }).then(res=>{
                      console.log(res)
                      window.location.reload()
                    })

                   
                  }}
                >
                  <div className="mb-2">
                    <input
                      type="text"
                      placeholder="Numaraları yapıştırın"
                   
                      className="e-i p-1"
                      value={pasteString}
                   
                      onPaste={(e) => {
                        const data = e.clipboardData.getData("text");
                        const a = [...pasted];

                        data.split("\n").forEach((val, ind) => {
                          if (val.split("\r").join("") != "") {
                            var num = val;

                            if (num.charAt(0) != "5") {
                              if (num.charAt(0) == "0") {
                                num = num.substring(1);
                              } else if (
                                num.charAt(0) == "9" &&
                                num.charAt(1) == "0"
                              ) {
                                num = num.substring(1);
                                num = num.substring(1);
                              } else {
                                return
                              }
                            }

                            a.push({ numara: num });
                          }
                        });

                
                        setPasted([
                          ...a.filter((n) => n.numara != ""),
                        ])

                        setPasteString("")
                      }}
                    />

                    <div className="row g-2">
                      {
                        pasted.length != 0 ? <button
                        type="submit"
                        className="bg-red-300  in hover:bg-red-400 hover:text-white text-red-600 rounded text-white font-medium py-1 mt-3 d-block"
                        onClick={e=>setPasted([])}><i className="fa-solid fa-trash"></i> Tümünü Sil </button> : ""
                      }
                      {pasted.map((val,i)=>{
                        return(
                          <div className="col-6">
                          <div
                          onClick={e=>{
                            const a = [...pasted];
                            console.log(a.filter(n=>n.numara != val.numara))
                            console.log(val)
                            setPasted(a.filter(n=>n.numara != val.numara))
                          }}
                          title="Silmek İçin Tıklayın" key={i} className="in active:bg-red-100 hover:bg-red-50 hover:text-slate-900 cursor-pointer text-sm text-slate-600 font-medium p-2 border-b">{val.numara}</div>
                          </div> )
                      })}
                    </div>
                  </div>

                 

                  {grupList ? (
                    <div className="mb-2">
                      <div className="font-medium text-sm">Gruplar </div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#f1f5f9",
                            border: "none",
                          }),
                          multiValueLabel: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "white",
                          }),

                          multiValueRemove: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#fca5a5",
                          }),
                        }}
                        //  value={seciliGruplar}
                        value={selectedGruplar}
                        onChange={(e) => {
                          setSelectedGruplar(e);
                        }}
                        form="musteriForm"
                        placeholder="Seç"
                        options={grupList}
                        isMulti={true}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex">
                    {ok ? (
                      <button className="text-xs in text-green-500 font-medium">
                        <i className="fa-solid fa-check"></i> Kaydedildi
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      type="submit"
                      className="e-btn bg-red-500 px-5 ms-auto d-block"
                    >
                      Kaydet
                    </button>
                  </div>
                </form>
              

              </div>
            ) : (
              ""
            )}

            {tab == "grup-ekle" ? (
              <div className="flex flex-column items-center in">
                <div className="flex items-center w-full">
                  <button
                    onClick={() => setTab("")}
                    className="rounded  text-zinc-600 hover:bg-slate-200  pe-2 text-xs"
                  >
                    <i className="fa-solid fa-chevron-left "></i>
                    Geri
                  </button>
                  <div className="font-medium mx-auto text-pri">Grup Ekle</div>
                </div>

                <form
                  id="musteriForm"
                  className="w-full mt-2"
                  onSubmit={(e) => addGrup(e)}
                >
                  <div className="mb-2">
                    <div className="font-medium text-sm">Grup İsmi</div>
                    <input
                      type="text"
                      onChange={() => setOk(false)}
                      placeholder="Grup İsmi"
                      required
                      className="e-i p-1 text-sm"
                    />
                  </div>

                  <div className="flex">
                    {ok ? (
                      <button className="text-xs in text-green-500 font-medium">
                        <i className="fa-solid fa-check"></i> Kaydedildi
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      type="submit"
                      className="e-btn bg-red-500 px-5 ms-auto d-block"
                    >
                      Kaydet
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}

            {tab == "grup-edit" ? (
              <div className="flex flex-column items-center in">
                <div className="flex items-center w-full">
                  <button
                    onClick={() => setTab("")}
                    className="rounded  text-zinc-600 hover:bg-slate-200  pe-2 text-xs"
                  >
                    <i className="fa-solid fa-chevron-left "></i>
                    Geri
                  </button>
                  <div className="font-medium mx-auto text-pri">Gruplar</div>
                </div>

                {grupList &&
                  grupList.map((val, i) => {
                    return (
                      <div
                        key={i}
                        className="mb-2 rounded w-full p-2 shadow-sm flex items-center bg-slate-100"
                      >
                        <div className="font-medium text-slate-700">
                          {val.label}
                        </div>
                        <i
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Bu grubu silmek istediğinize emin misiniz"
                              )
                            ) {
                              axios
                                .post(`/grupSil`, {
                                  grup: val.label,
                                })
                                .then((res) => {
                                  window.location.reload();
                                  e.target.parentElement.remove();
                                });
                            }
                          }}
                          className="fa-solid cursor-pointer hover:text-red-500 fa-trash text-red-400 ms-auto"
                        ></i>
                      </div>
                    );
                  })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-lg-8 col-12">
          {table != "kisayol" ? (
            <div className="in">
              <div className="mb-2 items-center flex justify-between">
                <div>
                  <input
                    onChange={(e) => {
                      var input, filter, table, tr, td, i, txtValue;
                      filter = e.target.value.toUpperCase();
                      table = document.getElementById("myTable");
                      tr = table.getElementsByTagName("tr");

                      for (i = 0; i < tr.length; i++) {
                        td = tr[i].getElementsByTagName("td")[1];
                        if (td) {
                          txtValue = td.textContent || td.innerText;

                          if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            tr[i].style.display = "";
                          } else {
                            tr[i].style.display = "none";
                          }
                        }
                      }
                    }}
                    type="text"
                    className="p-2 rounded  bg-slate-100"
                    placeholder="Müşteri Ara"
                  />
                </div>

                {secili.length != 0 ? (
                  <button
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Seçili müşterileri silmek istediğinize emin misiniz"
                        )
                      ) {
                        e.target.setAttribute("disabled", "true");
                        e.target.innerHTML =
                          '<i class="fa-solid fa-spinner fa-spin"></i>';
                        axios
                          .post(`/topluMusteriSil`, {
                            ids: secili.join(","),
                          })
                          .then((res) => {
                            window.location.reload();
                          });
                      }
                    }}
                    className="in px-2 py-1 text-sn  rounded font-medium bg-red-200 hover:bg-red-300 text-red-600"
                  >
                    <i className="fa-solid fa-trash"></i> Seçilileri Sil
                  </button>
                ) : (
                  ""
                )}

                <div>
                  <select
                    className="p-2 rounded font-medium bg-slate-100"
                    onChange={(e) => setgrupSec(e.target.value)}
                  >
                    <option value="">Tümü</option>
                    {grupList
                      ? grupList.map((val, i) => {
                          return (
                            <option key={i} value={val.label}>
                              {val.label}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>

              <div className="table-responsive">
                <div className="max-table">
                  <table className="table w-full ">
                    <thead className="border-b">
                      <tr>
                        <td></td>
                        <td className="font-medium p-2 text-sm">
                          <i className="fa-solid fa-user"></i> Ad Soyad
                        </td>
                        <td className="font-medium text-sm">
                          <i className="fa-solid fa-phone"></i> Telefon
                        </td>
                        <td className="font-medium text-sm">
                          <i className="fa-solid fa-calendar-days"></i> Gruplar
                        </td>
                      </tr>
                    </thead>
                    <tbody id="myTable">
                      {musteriler ? (
                        musteriler.map((val, i) => {
                          return (
                            <tr key={i} className="hover:bg-slate-100">
                              <td className="border-0">
                                <input
                                  id={`ch${i}`}
                                  type="checkbox"
                                  className="ch cursor-pointer"
                                  onChange={(e) => {
                                    if (!secili.includes(val.musteri_id)) {
                                      setSecili([...secili, val.musteri_id]);
                                    } else {
                                      const a = secili.filter(
                                        (n) => n != val.musteri_id
                                      );
                                      setSecili(a);
                                    }
                                  }}
                                  checked={secili.includes(val.musteri_id)}
                                />
                              </td>
                              <td className="text-pri font-medium p-2">
                                <label
                                  className="cursor-pointer"
                                  htmlFor={`ch${i}`}
                                >
                                  {" "}
                                  {val.ad_soyad}{" "}
                                </label>
                              </td>
                              <td className="font-medium text-slate-400 italic">
                                {val.telefon}
                              </td>
                              <td className="text-slate-400 text-sm relative big-grup-parent">
                                {val.gruplar
                                  ? val.gruplar.substr(0, 15) + "..."
                                  : "Yok"}
                                {val.gruplar ? (
                                  <div className="show-big-grup">
                                    {val.gruplar
                                      ? val.gruplar
                                          .split(",")
                                          .map((grup, i2) => {
                                            return (
                                              <div
                                                className="text-slate-600 font-medium border-b border-slate-600"
                                                key={i2}
                                              >
                                                {grup}
                                              </div>
                                            );
                                          })
                                      : ""}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="in row g-2">
                <form
                  className="row g-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.target.style.opacity = "0.6";
                    axios
                      .post(`/insert/kisayollar/`, {
                        icerik: e.target[0].value,
                        to_grup: e.target[1].value,
                      })
                      .then((res) => {
                        e.target.style.opacity = "1";
                        e.target.reset();
                        setKisayollar(res.data);
                      });
                  }}
                >
                  <div className="col-8">
                    <input
                      type="text"
                      required
                      placeholder="Kısayol SMS Metni"
                      className="p-2 rounded bg-slate-100 w-full"
                    />
                  </div>
                  <div className="col-3">
                    <select className="p-2 rounded bg-slate-100 w-full">
                      <option value="">Grup</option>
                      {grupList
                        ? grupList.map((val, i) => {
                            return (
                              <option key={i} value={val.label}>
                                {val.label}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  <div className="col-1">
                    <button
                      type="submit"
                      className="w-full bg-green-200 hover:bg-green-300 text-green-600 p-2 rounded font-medium"
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </form>
                {kisayollar && kisayollar.length == 0 ? (
                  <div className="col-12">
                    <div className="bg-amber-200 text-amber-600 border-2 border-amber-600 p-2 font-medium text-center">
                      Hiç kısayol yok.
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {kisayollar &&
                  kisayollar.map((val) => {
                    return (
                      <div className="col-4" key={val.ks_id}>
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                "Bu kısayolu silmek istediğinize emin misiniz"
                              )
                            ) {
                              axios
                                .post(`/delete/kisayollar/ks_id/${val.ks_id}`)
                                .then((res) => {
                                  setKisayollar(res.data);
                                });
                            }
                          }}
                          className="p-2 h-full flex items-center justify-center text-center text-sm font-medium active:scale-90 transition-all shadow hover:bg-slate-200 cursor-pointer rounded-lg bg-slate-100"
                        >
                          {val.icerik}{" "}
                          <span className="text-red-500 ms-1">
                            {val.to_grup ? `(${val.to_grup})` : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Musteriler;
