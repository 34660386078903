import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

function Header({ amount }) {
  const location = useLocation();

  return (
    <div className="header rounded-lg shadow-sm px-3 py-3">
      <div className="logo in">
        <div className="logo-title font-bold leading-3 mb-2 text-lg">
          MHD HOLDING
        </div>
        <div className="logo-alt font-medium leading-3 text-sm">SMS PANELİ</div>
      </div>

      <div className="md:hidden">
        <div className="burger w-6">
          <div className="w-full h-1 mb-1 bg-slate-600 rounded"></div>
          <div className="w-full h-1 mb-1 bg-slate-600 rounded"></div>
          <div className="w-full h-1 mb-1 bg-slate-600 rounded"></div>
        </div>
      </div>

      <ul className=" flex-fill items-center md:flex hidden">
        <li className="ms-auto">
          <Link to={"/"} className={location.pathname == "/" ? "active" : ""}>
            Genel Bakış
          </Link>
        </li>
        <li className="">
          <Link
            className={location.pathname == "/sms-gonder" ? "active" : ""}
            to={"/sms-gonder"}
          >
            SMS Gönder
          </Link>
        </li>
        <li>
          <Link
            className={location.pathname == "/musteriler" ? "active" : ""}
            to={"/musteriler"}
          >
            Rehber
          </Link>
        </li>

        <li className="ms-auto">
          <div className="flex items-center">
            <div
              id="kalan"
              data-tooltip-content="Kalan SMS Adedi"
              className="kalan-sms rounded-full h-full rounded-r-none px-2 py-1 text-sm font-medium text-white"
            >
              {amount ? (
                amount
              ) : (
                <i className="fa-solid fa-spin fa-spinner"> </i>
              )}
            </div>
            <Tooltip anchorId="kalan" place="bottom" className="text-xs" />
            <div className=" bg-slate-200 rounded-full rounded-l-none px-2 py-1 text-sm font-medium text-white">
              <Link
                to={"/ayarlar"}
                id="ayarlarLink"
                data-tooltip-content="Ayarlar"
                title="Ayarlar"
              >
                <i className="fa-gear fa-solid text-slate-500 cursor-pointer hover:text-slate-700"></i>
              </Link>
              <Tooltip anchorId="ayarlarLink" place="bottom" />

              <i
                onClick={() => {
                  if (
                    window.confirm("Çıkış yapmak istediğinize emin misiniz?")
                  ) {
                    localStorage.sms_token = "";
                    window.location = "/";
                  }
                }}
                data-tooltip-content="Çıkış Yap"
                title="Çıkış Yap"
                id="cikisLink"
                className="fa-solid fa-arrow-right-from-bracket text-slate-500 hover:text-slate-700 ms-2 cursor-pointer "
              ></i>
              <Tooltip anchorId="cikisLink" place="bottom" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Header;
