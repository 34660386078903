import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Index() {
  const [selectedAy, setSelectedAy] = useState(null);
  const [data, setData] = useState(null);
  const [secili, setSecili] = useState([]);

  return (
    <div className="page p-2 rounded-lg shadow-sm">
      <div className="row g-2">
        <div className="col-lg-7 col-12">
          <div>
            {data == null ? (
              <div className="p-3 in">
                <div className="logo text-center ">
                  <div className="text-pri font-bold leading-3 mb-2 text-lg">
                    MHD HOLDING
                  </div>
                  <div className="logo-alt font-medium leading-3 text-sm">
                    SMS PANELİ
                  </div>
                </div>

                <div className="font-medium text-slate-600 text-center leading-4 text-sm mt-2">
                  Hoşgeldiniz. Takvimden bir gün seçerek o güne ait kayıtlı
                  sms'lere bakabilirsiniz
                </div>

                <div className="flex mt-2 justify-center">
                  <Link
                    to={"/sms-gonder"}
                    className="bg-pri text-white text-sm rounded px-2 py-1 font-medium"
                  >
                    SMS Gönder
                  </Link>
                  <button
                    onClick={(e) => {
                      setSelectedAy(null);
                      axios.get(`/getSmsKayitlar/${0}`).then((res) => {
                        setData(res.data);
                      });
                    }}
                    className="bg-slate-200 ms-2 hidden text-slate-600 text-sm rounded px-2 py-1 font-medium"
                  >
                    Kayıtlı SMS'ler
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {data != null && data.length == 0 ? (
              <div className="text-center mt-2">
                Bu güne ait otomatik sms kaydı yoktur
              </div>
            ) : (
              ""
            )}

            {data && data.length != 0 ? (
              <>
                <div className="max-table in">
                  <table className="table w-full">
                    <thead>
                      <tr className="bg-red">
                        <th></th>
                        <th className="p-1 text-start">Gün</th>
                        <th className="text-start">Numara</th>
                        <th className="text-start">Başlık</th>
                        <th className="text-start">Mesaj</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((val) => {
                        return (
                          <tr key={val.os_id}>
                            <td className="border-0">
                              <input
                                type="checkbox"
                                className="ch"
                                onChange={(e) => {
                                  if (!secili.includes(val.os_id)) {
                                    setSecili([...secili, val.os_id]);
                                  } else {
                                    const a = secili.filter(
                                      (n) => n != val.os_id
                                    );
                                    setSecili(a);
                                  }
                                }}
                                checked={secili.includes(val.os_id)}
                              />
                            </td>
                            <td className="p-1 text-pri font-medium text-start border-0">
                              {val.gun}
                            </td>
                            <td className="border-0 font-medium">
                              {val.numara}
                            </td>
                            <td className="border-0 font-medium">
                              {val.baslik}
                            </td>
                            <td className="border-0">{val.mesaj}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {secili.length != 0 ? (
                  <button
                    onClick={(e) => {
                      if (!window.confirm("Emin misiniz")) {
                        return;
                      }

                      axios
                        .post(`/deleteKayit`, {
                          ids: secili.join(","),
                        })
                        .then((res) => {
                          window.location.reload();
                        });
                    }}
                    className="e-btn mt-2"
                  >
                    Seçilileri Sil
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="col-lg-5 col-12">
          <div>
            <div className="flex w-full items-center rounded-t justify-between bg-slate-200 py-2 px-2">
              <div className="font-medium  text-slate-600  ">
                <i className="fa-regular fa-calendar-days"></i> Takvim
              </div>

              <button
                onClick={(e) => {
                  setSelectedAy(null);
                  axios.get(`/getSmsKayitlar/${0}`).then((res) => {
                    setData(res.data);
                  });
                }}
                className="text-sm py-1 px-2 text-slate-800 bg-slate-400 rounded font-medium"
              >
                Tümü
              </button>
            </div>

            <div className="in ay-select mb-2 bg-slate-100 rounded-b-lg pt-1">
              {[...Array(30).keys()].map((val, i) => {
                return (
                  <div
                    onClick={(e) => {
                      setSelectedAy(val);
                      axios.get(`/getSmsKayitlar/${val + 1}`).then((res) => {
                        setData(res.data);
                      });
                    }}
                    className={`ay ${selectedAy == val ? "ay-active" : ""}`}
                    key={i}
                  >
                    {val + 1}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
