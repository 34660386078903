import axios from "axios";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";

export default function Onay() {
  const [users, setUsers] = useState(null);
  const [ap, setAp] = useState(false);
  useEffect(() => {
    axios.get("/get/sms_admin?onay=0").then((res) => {
      setTimeout(() => {
        setUsers(res.data);
      }, 300);
    });
  }, []);

  return (
    <div className="page p-2 in rounded-lg bg-transparent shadow-sm">
      <div className="row g-3">
        <div className="col-lg-4 col-12">
          <div>
            <div className="flex items-center justify-between">
              <div className="mb-2 font-medium text-slate-600">
                <i className="fa-solid fa-user-gear"></i> Onay Bekleyen
                Yöneticiler
              </div>
            </div>

            {users == null ? (
              <>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div class="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div class="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div class="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div class="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div class="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div class="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div class="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div class="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div class="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div class="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div class="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div class="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
              </>
            ) : (
              users.map((val) => {
                return (
                  <div className="flex-wrap flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                    <div>
                      <i className="fa-user-gear fa-solid text-3xl text-slate-500"></i>
                    </div>
                    <div>
                      <div className="font-medium leading-4 text-red-500">
                        {val.isim}
                      </div>
                      <div className="font-medium text-sm text-slate-600">
                        <i className="fa-solid fa-phone"></i> {val.tel}
                      </div>
                    </div>
                    <div className={`ms-auto `}>
                      <i
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Bu yöneticiyi silmek istiyormusunuz"
                            )
                          ) {
                            axios
                              .post(`/delete/sms_admin/id/${val.id}`)
                              .then((res) => {
                                setUsers(res.data);
                              });
                          }
                        }}
                        className="fa-solid text-red-400 cursor-pointer hover:text-red-600 fa-trash"
                      ></i>
                    </div>

                    <div
                      onClick={(e) => {
                        axios
                          .post(`/update/sms_admin/id/${val.id}`, {
                            onay: 1,
                          })
                          .then((res) => {
                            setUsers(res.data);
                          });
                      }}
                      className=" w-full tex-sm bg-green-200 hover:bg-green-300 cursor-pointer rounded p-2 text-green-600 text-center font-medium"
                    >
                      Onayla
                    </div>
                  </div>
                );
              })
            )}

            {users && users.length == 0 ? (
              <div className="text-red-500">
                Onay Bekleyen Yönetici bulunmamaktadır
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
