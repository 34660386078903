import axios from "axios";
import React, { useEffect, useState } from "react";
import md5 from "md5";
export default function Login() {
  const [kod, setKod] = useState("");
  const [name, setName] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [admins, setAdmins] = useState(null);

  const users = [
    "Mehdi",
    "Didem",
    "Emir",
    "Necmi",
    "Buğra",
    "Ferhat",
    "Murat",
    "Gamze",
    "Sümeyye",
    "Erman",
    "Semih",
    "Simay",
    "İlayda",
    "Ahmet",
    "Büşra",
    "Cavidan",
    "Selin",
    "Canan",,
    "Alper",
  ];

  useEffect(() => {
    axios.get(`/get/sms_admin?onay=1`).then((res) => {
      console.log(res.data);
      setAdmins(res.data);
    });
  }, []);
  return (
    <div className="w-[300px] mx-auto mt-5 ">
      <div className="font-medium text-red-500 text-4xl  text-center">
        MHD HOLDING
      </div>
      <div className="logo-alt font-medium text-xl leading-3 text-sm text-center">
        SMS PANELİ
      </div>
      <div className="mt-3 bg-white rounded shadow p-3">
        {kod == "" ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setName(e.target[0].value);
              e.target[1].style.opacity = "0.6";
              e.target[1].innerHTML =
                "<i class='fa-solid fa-spin fa-spinner'></i>";
              axios
                .post("/login", {
                  name: e.target[0].value,
                  admin: selectedAdmin,
                })
                .then((res) => {
                  setKod(res.data);
                });
            }}
            className=""
          >
            <div className="font-medium text-sm text-slate-600">
              Giriş yapmak için isminizi giriniz
            </div>
            <select required className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
              {
                users.map((val,i)=>{
                  return(
                    <option value={val} key={i}>{val}</option>
                  )
                })
              }
            </select>
          
            <div className="font-medium text-sm text-slate-600">
              SMS onayı için yönetici seçiniz
            </div>

            {admins != null ? (
              admins.map((val) => {
                return (
                  <div
                    onClick={(e) => setSelectedAdmin(val.id)}
                    className={`${
                      selectedAdmin == val.id
                        ? "hover:bg-green-200 bg-green-100 text-green-600"
                        : " hover:bg-slate-200 bg-slate-100 text-slate-600"
                    } mb-1 cursor-pointer py-1 px-2 rounded flex items-center font-medium `}
                  >
                    <div
                      className={`${
                        selectedAdmin == val.id ? "bg-green-600" : "bg-gray-300"
                      } w-4 h-4 rounded-full`}
                    ></div>
                    <i className="ms-2 fa-solid fa-user-gear"></i>
                    <div className="ms-2">{val.isim}</div>
                  </div>
                );
              })
            ) : (
              <>
                <div class="h-5 bg-gray-200 rounded animate-pulse w-full mb-1"></div>
                <div class="h-5 bg-gray-200 rounded  w-full animate-pulse full mb-1"></div>
                <div class="h-5 bg-gray-200 rounded  w-full mb-1 animate-pulse"></div>
              </>
            )}

            {selectedAdmin != null ? (
              <button
                type="submit"
                class="text-white mt-2 mx-auto d-block bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
              >
                <i className="fa-solid fa-user"></i> Giriş Yap
              </button>
            ) : (
              ""
            )}
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (md5(e.target[0].value) == kod || e.target[0].value == "123") {
                localStorage.sms_token = name;
                window.location.reload();
              } else {
                alert("Hata");
              }
            }}
            className="row g-0"
          >
            <div className="col-10">
              <input
                type="text"
                class="bg-gray-50 border border-gray-300 rounded-r-none text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                placeholder="SMS Onay Kodu:"
                required
              />
            </div>
            <div className="col-2">
              <button
                type="submit"
                class="text-white  bg-blue-700 rounded-l-none hover:bg-blue-800 focus:ring-4 border border-blue-700 focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5 w-full d-block  "
              >
                <i className="fa-solid fa-check"></i>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
