import axios from "axios";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
function Ayarlar() {
  const [users, setUsers] = useState(null);
  const [ayar, setAyar] = useState(null)
  const [ap, setAp] = useState(false);
  useEffect(() => {
    axios.get("/get/sms_admin").then((res) => {
      setTimeout(() => {
        setUsers(res.data);
      }, 300);
    });

    axios.get("/getAyar").then((res) => {
        setAyar(res.data);
        console.log(res.data)
   
    });
  }, []);

  return (
    <div className="page p-2 in rounded-lg  shadow-sm">
      <div className="row g-3">
        <div className="col-lg-4 col-12">
          <div>
            <div className="flex items-center justify-between">
              <div className="mb-2 font-medium text-slate-600">
                <i className="fa-solid fa-user-gear"></i> Yöneticiler
              </div>

              <i
                onClick={(e) => setAp(!ap)}
                className="fa-solid fa-plus text-lg text-slate-600 hover:text-red-500 cursor-pointer"
              ></i>
            </div>

            {ap ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setUsers(null);
                  axios
                    .post("/insert/sms_admin", {
                      isim: e.target[0].value,
                      tel: e.target[1].value,
                      onay: 0,
                    })
                    .then((res) => {
                      e.target.reset();
                      setUsers(res.data);
                    });
                }}
                className="row g-1 mb-2 in"
              >
                <div className="col-12">
                  <input
                    required
                    type="text"
                    placeholder="İsim Soyisim"
                    className="bg-slate-100 px-2 py-1 rounded w-full border text-sm"
                  />
                </div>

                <div className="col-9">
                  <InputMask
                    required
                    placeholder="Telefon No (5xx xxx xxxx)"
                    className="512-021 10020 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-sm bg-slate-100 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-sm bg-slate-100 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-smbg-slate-100 px-2 py-1 rounded w-full border text-sm"
                    mask="599 999 9999"
                    maskChar=""
                  />
                </div>
                <div className="col-3">
                  <button
                    type="submit"
                    className="py-1  bg-red-200 hover:bg-red-300 text-red-600 rounded d-block w-full  text-sm border border-red-200 "
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                </div>

                <div className="col-12">
                  <div className="text-slate-400 text-sm font-medium leading-4">
                    *Eklediğiniz kişi onay sırasına gidecektir.
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
            {users == null ? (
              <>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div className="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div className="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div className="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div className="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div className="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div className="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div className="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div className="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
                <div className="flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                  <div>
                    <div className="h-8 bg-gray-300 rounded-full w-8 mt-2"></div>
                  </div>
                  <div className="">
                    <div className="font-medium leading-4 text-red-500">
                      <div className="h-2.5 bg-gray-300 rounded-full w-36"></div>
                    </div>
                    <div className="font-medium text-sm text-slate-600">
                      <div className="h-2.5 bg-gray-300 rounded-full w-24 mt-2"></div>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <div className="h-4 bg-gray-300 rounded-full w-4"></div>
                  </div>
                </div>
              </>
            ) : (
              users.map((val,i) => {
                return (
                  <div key={i} className="flex-wrap flex border mb-2 items-center gap-2 rounded p-2 bg-slate-100">
                    <div className={val.onay == 0 ? "opacity-60" : ""}>
                      <i className="fa-user-gear fa-solid text-3xl text-slate-500"></i>
                    </div>
                    <div className={val.onay == 0 ? "opacity-60" : ""}>
                      <div className="font-medium leading-4 text-red-500">
                        {val.isim}
                      </div>
                      <div className="font-medium text-sm text-slate-600">
                        <i className="fa-solid fa-phone"></i> {val.tel}
                      </div>
                    </div>
                    <div
                      className={`ms-auto ${val.onay == 0 ? "opacity-60" : ""}`}
                    >
                      <i
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Bu yöneticiyi silmek istiyormusunuz"
                            )
                          ) {
                            axios
                              .post(`/delete/sms_admin/id/${val.id}`)
                              .then((res) => {
                                setUsers(res.data);
                              });
                          }
                        }}
                        className="fa-solid text-red-400 cursor-pointer hover:text-red-600 fa-trash"
                      ></i>
                    </div>
                    {val.onay == 0 ? (
                      <div className=" w-full tex-xs bg-red-200 rounded px-2 text-red-600 text-center font-medium">
                        Onay Bekliyor
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="col-lg-4 col-12">
        <div className="mb-2 font-medium text-slate-600">
                <i className="fa-solid fa-gear"></i> Ayarlar
              </div>

              <div>
                <form onSubmit={e=>{
                  e.preventDefault()
                  e.target[1].innerHTML = "<i class='fa-solid fa-spinner fa-spin'></i>"
                  axios.post(`/update/ayarlar/id/1`,{
                    iptal_metin: e.target[0].value
                  }).then(res=>{
                    setTimeout(()=>{
                      e.target[1].innerHTML = "Kaydet"
                    },400)
                  })
                }}>
                <div className="font-medium">Abonelikten Çık Metni:</div>

                <textarea defaultValue={ayar ? ayar[0]["iptal_metin"] : ""} rows={2} type="text" className="e-i shadow borderr text-sm" placeholder="Sms İptal Metni"></textarea>
                  <button  className="bg-pri text-white text-sm rounded px-2 py-1 font-medium">Kaydet</button>
                </form>
              </div>
        </div>
      </div>
    </div>
  );
}

export default Ayarlar;
