import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
axios.defaults.baseURL = 
window.location.hostname == "localhost"  ?
"https://sms-backend.yazilimweb.com/sms"
:"https://sms-backend.yazilimweb.com/sms";


export const iletiMerkezi = {
    username: "5442292823",
    pass:"602660"
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);